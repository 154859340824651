import { FUNNELS } from '~/constants';
import { Funnel } from '~/types';

const EXCLUDED_FUNNELS: Funnel[] = ['main'];

export const funnelsList = Object.values(FUNNELS).filter((funnel) => !EXCLUDED_FUNNELS.includes(funnel as Funnel));

export const getFunnelPaths = () => {
  return funnelsList.map((funnel) => ({
    params: {
      funnel,
    },
  }));
};
