import {
  PlasmicComponent,
  extractPlasmicQueryData,
  ComponentRenderData,
  PlasmicRootProvider,
} from '@plasmicapp/loader-nextjs';
import type { GetStaticPaths, GetStaticProps } from 'next';
import Error from 'next/error';
import { useRouter } from 'next/router';

import { config } from '~/config';
import { FUNNELS } from '~/constants';
import { Root } from '~/layouts/landings/root';
import { funnelsList } from '~/utils/funnel-paths';

import { PLASMIC } from '../plasmic-init';

import Landing from './landing';
import UpsellBook from './upsells/book';

const getUpsellRoutes = () => {
  const paths: Partial<Record<string, () => JSX.Element>> = {};

  for (const funnel of funnelsList) {
    paths[`/${funnel}/upsells/book`] = UpsellBook;
  }

  return paths;
};

const PATH_TO_PAGE_MAP: Partial<Record<string, () => JSX.Element>> = {
  '/': Root,
  '/cortisol-1': Landing,
  '/cortisol-2': Landing,
  '/cortisol-3': Landing,
  '/cortisol-1/plans': Landing,
  '/cortisol-2/plans': Landing,
  '/cortisol-3/plans': Landing,
  '/cortisol-4/plans': Landing,
  '/cortisol-5/plans': Landing,
  '/upsells/book': UpsellBook,
  ...getUpsellRoutes(),
};

export default function PlasmicLoaderPage(props: {
  plasmicData?: ComponentRenderData;
  queryCache?: Record<string, unknown>;
  path: string;
}) {
  const { plasmicData, queryCache, path } = props;
  const router = useRouter();
  const NativePage = PATH_TO_PAGE_MAP[path];

  // NOTE: render native codebase page if its found
  if (NativePage) {
    return <NativePage />;
  } else if (!plasmicData || plasmicData.entryCompMetas.length === 0) {
    return <Error statusCode={404} />;
  }

  const pageMeta = plasmicData.entryCompMetas[0];

  return (
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmicData}
      prefetchedQueryData={queryCache}
      pageParams={pageMeta.params}
      pageQuery={router.query}
      globalVariants={[]}
    >
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { catchall } = context.params ?? {};
  const locale = context.locale || 'en-US';
  const plasmicPath =
    typeof catchall === 'string' ? catchall : Array.isArray(catchall) ? `/${catchall.join('/')}` : '/';

  const plasmicData = await PLASMIC.maybeFetchComponentData(plasmicPath);

  if (!plasmicData) {
    // non-Plasmic catch-all
    return {
      props: {
        path: plasmicPath,
      },
    };
  }
  const pageMeta = plasmicData.entryCompMetas[0];
  // Cache the necessary data fetched for the page
  const queryCache = await extractPlasmicQueryData(
    <PlasmicRootProvider loader={PLASMIC} prefetchedData={plasmicData} pageParams={pageMeta.params}>
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>,
  );

  return {
    props: {
      path: plasmicPath,
      locale,
      plasmicData,
      queryCache,
    },
    revalidate: config.IS_DEV ? 100 : undefined,
  };
};
const excludedPaths = ['/checkout'];

export const getStaticPaths: GetStaticPaths = async () => {
  const pageModules = await PLASMIC.fetchPages();

  const filteredPageModules = pageModules.filter((mod) => !excludedPaths.includes(mod.path));

  // TODO: move all pages from plasmic to codebase
  const paths = filteredPageModules.map((mod) => ({
    params: {
      catchall: mod.path.substring(1).split('/'),
    },
  }));

  return {
    paths: paths.concat(
      ...[
        {
          params: {
            catchall: [FUNNELS['cortisol-2']],
          },
        },
        {
          params: {
            catchall: [FUNNELS['cortisol-3']],
          },
        },
      ],
    ),
    fallback: 'blocking',
  };
};
