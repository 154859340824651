import { Button } from '@shared/design-system/atoms/button';
import Link from 'next/link';
import { useRef } from 'react';

import CharPieOneImage from '~/assets/images/landing/chart-pie-1.png';
import CharPieTwoImage from '~/assets/images/landing/chart-pie-2.png';
import CharPieThreeImage from '~/assets/images/landing/chart-pie-3.png';
import CharPieFourImage from '~/assets/images/landing/chart-pie-4.png';
import ChartPieBigTwoImage from '~/assets/images/landing/chart-pie-big-70.png';
import ChartPieBigOneImage from '~/assets/images/landing/chart-pie-big-80.png';
import LowerWeightIllustrationTwo from '~/assets/images/landing/lower-weight-illustration-2.png';
import LowerWeightIllustrationThree from '~/assets/images/landing/lower-weight-illustration-3.png';
import LowerWeightIllustrationFour from '~/assets/images/landing/lower-weight-illustration-4.png';
import LowerWeightIllustrationFive from '~/assets/images/landing/lower-weight-illustration-5.png';
import ReviewOneImage from '~/assets/images/landing/review-1.png';
import ReviewTwoImage from '~/assets/images/landing/review-2.png';
import ReviewThreeImage from '~/assets/images/landing/review-3.png';
import ReviewFourImage from '~/assets/images/landing/review-4.jpeg';
import ReviewFiveImage from '~/assets/images/landing/review-5.png';
import ReviewSixImage from '~/assets/images/landing/review-6.png';
import { Grid } from '~/components/grid';
import { useAppRouter } from '~/hooks/useAppRouter';
import { Funnel } from '~/types';

import Banner from '../_shared/banner';
import Footer from '../_shared/footer';
import Header from '../_shared/header';

import Comparison from './_components/comparison';
import Faq from './_components/faq';
import Hero from './_components/hero';
import Hormones from './_components/hormones';
import Purchase from './_components/purchase';
import PurchaseOld from './_components/purchase-old';
import Researched from './_components/researched';
import Reviews from './_components/reviews';
import Science from './_components/science';
import Testimonials from './_components/testimonials';

const funnelsWithOldPurchaseBlock: Funnel[] = ['cortisol-3', 'cortisol-5', 'main'];

export const Root = () => {
  const purchaseContainerRef = useRef<HTMLDivElement>(null);
  const { funnelName } = useAppRouter();
  const isOldPurchaseBlock = funnelsWithOldPurchaseBlock.includes(funnelName);

  return (
    <Grid className='[--max-content:1168px]'>
      <Banner />
      <Header
        isBadgeVisible
        button={
          <Link href={`/ingredients`}>
            <Button size='sm' variant='outline'>
              Ingredients
            </Button>
          </Link>
        }
      />
      <Hero
        purchaseContainerRef={purchaseContainerRef}
        title='High Cortisol?'
        subTitle='Harmonia will help you to:'
        benefitItems={[
          { id: 1, text: 'Lower your stress levels and anxiety' },
          { id: 2, text: 'Relieve mood swings and improve sleep quality' },
          { id: 3, text: 'Improve your hormonal balance and overall health' },
        ]}
      />
      <Researched />
      <Hormones
        primaryGridProps={{
          headline: 'Lower Your Stress Hormones',
          items: [
            {
              id: 1,
              image: LowerWeightIllustrationFive,
              title: 'Assists with emotional eating',
              paragraph: 'Supports progesterone and estrogen levels to assist with your impulsive curb carb cravings.',
            },
            {
              id: 2,
              image: LowerWeightIllustrationTwo,
              title: 'Supports Lower Cortisol & Relaxation',
              paragraph:
                'Contains plant extracts that highly efficient to help reduce stress, ease tension, and promote a calm mind.',
            },
            {
              id: 3,
              image: LowerWeightIllustrationThree,
              title: 'Improves sleep and overall well-being',
              paragraph: 'Improved formula shown to help with relaxation and restless sleep',
            },
            {
              id: 4,
              image: LowerWeightIllustrationFour,
              title: 'Addresses hormonal imbalances',
              paragraph: 'Helps women that have PCOS menopause or thyroid issues.',
            },
          ],
        }}
        secondaryGridProps={{
          purchaseContainerRef,
          title: `Modern Living\nMesses Up Our Stress Hormone`,
          subTitle: ' Harmonia Cocktail is the first smart cocktail that fights exactly that.',
          items: [
            {
              id: 1,
              image: ChartPieBigOneImage,
              title: '80%',
              subTitle:
                'of women experience symptoms of chronic stress and cortisol dysregulation, leading to irregular menstrual cycles, anxiety, fatigue, and brain fog.',
            },
            {
              id: 2,
              image: ChartPieBigTwoImage,
              title: '70%',
              subTitle:
                'of women with high cortisol struggle with poor sleep quality and wake up feeling unrefreshed, even after 7-9 hours of sleep that leads to restlessness.',
            },
          ],
          ctaLabel: 'Harmonia can solve it',
        }}
        tertiaryGridProps={{
          purchaseContainerRef,
          preTitle: 'Key ingredients',
          title: `Harmonia Cortisol\nBlend`,
          timeline: {
            title: 'powerful strains that boosts healthy cortisol drop & improves overall health',
            items: [
              {
                id: 1,
                title: 'L-Theanine',
                content:
                  'helps calm the brain by increasing levels of neurotransmitters like GABA, v serotonin, and dopamine',
              },
              {
                id: 2,
                title: 'Ashwagandha',
                content: 'Helps reduce stress, which can support a more balanced mood and promote healthier habits.',
              },
              {
                id: 3,
                title: 'Myo Inositol',
                content:
                  'Balances hormone levels, reduces cravings and stabilizes energy levels, which facilitate healthier eating and exercise habits.',
              },
              {
                id: 4,
                title: 'D-Chiro Inositol',
                content:
                  'Improves insulin function, which can help reduce insulin resistance often linked to conditions like PCOS',
              },
              {
                id: 5,
                title: 'Rhodiola Rosea',
                content: 'Enhances stress resilience with Rhodiola helps prevent fatigue and emotional eating',
              },
              {
                id: 6,
                title: 'Black pepper extract',
                content:
                  'Supports metabolism through nutrient absorption, aids digestion, and provides antioxidant support.',
              },
              {
                id: 7,
                title: 'Tumeric',
                content:
                  'Supports healthy insulin sensitivity and helps regulate lipid metabolism. It also offers powerful antioxidant and anti-inflammatory benefits.',
              },
              {
                id: 9,
                title: 'Phosphatidylserine',
                content: 'Support brain health and cognitive function, can improve focus and decision-making',
              },
              {
                id: 10,
                title: 'Other vitamins',
                content:
                  'Vitamin C, Vitamin D, Vitamin B6, Folate, Vitamin B12, Magnesium, Chronium and Potassium. All of which works in a support to boost the effectivness of the Harmonia drink.',
              },
            ],
            ctaLabel: 'Buy now',
          },
        }}
      />
      <Science
        title={`The Science\nSupporting Harmonia`}
        subTitleParts={[{ label: 'based on ' }, { label: 'clinical studies.', withHighlight: true }]}
        items={[
          { id: 1, image: CharPieOneImage, title: '91%', content: 'Experienced to be less anxious than usual' },
          {
            id: 2,
            image: CharPieTwoImage,
            title: '68%',
            content: 'Hormonal balance in relation to PCOS and menstrual cycles',
          },
          {
            id: 3,
            image: CharPieThreeImage,
            title: '74%',
            content: 'Optimized nutrient intake that contributed to overall well-being',
          },
          { id: 4, image: CharPieFourImage, title: '95%', content: 'Energy levels increase along with restful sleep' },
        ]}
      />
      {isOldPurchaseBlock ? <PurchaseOld ref={purchaseContainerRef} /> : <Purchase ref={purchaseContainerRef} />}
      <Testimonials
        title={`Thousands of\nHappy Clients`}
        subTitle='This could be you - check out these reviews from happy customers around the works'
        testimonials={[
          {
            id: 1,
            title: 'I am feeling more balanced and healthier than ever',
            description:
              "I've been drinking the cortisol cocktail for three months, and the results have been nothing short of remarkable. My stress levels are much lower, and I finally feel like myself again—calmer, more focused, and less overwhelmed. The drink is delicious and easy to incorporate into my daily routine. Highly recommend giving it a try",
            name: 'Laura U.',
            rating: 5,
            image: ReviewOneImage,
          },
          {
            id: 2,
            title: 'It’s become a must-have in my daily routine',
            description:
              'I started using the cortisol cocktail recently, and I can’t believe the difference in my stress levels. I used to feel constantly on edge, but now I stay calm even on the busiest days. My mind feels clearer, and I can finally focus without feeling overwhelmed.',
            name: 'Jessica E.',
            rating: 5,
            image: ReviewTwoImage,
          },
          {
            id: 3,
            title: 'I never expected a simple drink to make such a difference',
            description:
              'For years, I struggled with restless nights and waking up exhausted. Since adding this drink to my routine, I fall asleep faster and wake up feeling truly refreshed. No more tossing and turning or staring at the ceiling at 3 AM.',
            name: 'Sandra',
            rating: 5,
            image: ReviewThreeImage,
          },
          {
            id: 4,
            title: 'Even my family has noticed the change',
            description:
              'I never realized how much stress was affecting me until I started taking this. My mood has stabilized, I don’t feel as irritable, and I finally have the patience I used to. It’s like my nervous system is getting the support it desperately needed.',
            name: 'Anna T.',
            rating: 5,
            image: ReviewFourImage,
          },
          {
            id: 5,
            title: 'This stuff is a game-changer',
            description:
              'The daily energy crashes used to ruin my afternoons, but that’s completely changed. Instead of feeling drained by mid-day, I now have steady energy from morning to night. I don’t rely on coffee as much, and I’m way more productive.',
            name: 'Tina R.',
            rating: 5,
            image: ReviewFiveImage,
          },
          {
            id: 6,
            title: 'One unexpected benefit? My skin looks amazing',
            description:
              'I used to deal with random breakouts, and nothing seemed to help. Since using this, my skin is clearer, less inflamed, and just healthier overall. I didn’t even realize how much stress was affecting my complexion until now!',
            name: 'Nichole E.',
            rating: 5,
            image: ReviewSixImage,
          },
        ]}
      />
      <Reviews
        reviews={[
          {
            id: 1,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-1.mp4',
            content: 'After six weeks, this natural cocktail eased my stress, improved my focus, and tasted amazing!',
          },
          {
            id: 2,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-2.mp4',
            content: 'I felt calmer and more balanced—plus the flavor was a delightful bonus.',
          },
          {
            id: 3,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-3.mp4',
            content:
              'This drink transformed my routine in 4 weeks: less stress, steady energy, and a refreshingly tasty blend.',
          },
          {
            id: 4,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-4.mp4',
            content:
              'With this cocktail, my stress levels dropped, my mind feels clearer—all while enjoying a smooth, natural flavor.',
          },
          {
            id: 5,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-5.mp4',
            content:
              'I’m thrilled: 5 weeks of this drink led to deeper sleep, improved mood, and a delicious, energizing taste.',
          },
        ]}
      />
      <Comparison
        title={`Harmonia\nCortisol Cocktail`}
        subTitle={`Compared To Other,\n Cortisol Lowering Drinks`}
        tableItems={[
          '"Intelligent" cortisol support system',
          'Ingredients adapt to unique needs of your body',
          'Releases stress, improves mood and helps with metabolism',
          'Helps address symptoms of Menopause and PCOS',
          'Plant-based formula',
          "Free from GMO's, corn and gluten. Vegan friendly.",
          'Amazing orange taste',
          'Amazing taste with 0 sugar',
          'Puts your cortisol levels into place',
          'Made in the USA',
        ]}
      />
      <Faq />
      <Footer />
    </Grid>
  );
};
